import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, shareReplay} from 'rxjs/operators';
import {combineLatest, map} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {KeycloakService} from 'keycloak-angular';
import {CONTROL_CENTER_ROLES, READ_DATA_ROLES} from '@app/roles';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit {
  isMenuFixed = false;
  isVisible = false;
  controlCenterRoles = CONTROL_CENTER_ROLES;
  readDataRoles = READ_DATA_ROLES;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private keycloakService: KeycloakService,
    private destroyRef: DestroyRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const isHandset$ = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset, Breakpoints.TabletPortrait, Breakpoints.TabletLandscape])
      .pipe(
        map((result) => result.matches),
        shareReplay(),
      );

    const isMapView$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map((event: NavigationEnd) => event.url.includes('map') || event.url.includes('registration')));
    combineLatest([isHandset$, isMapView$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isHandset, isMapView]) => {
        this.isMenuFixed = !isHandset && !isMapView;
        this.changeDetectorRef.detectChanges();
      });
  }

  onLogout() {
    this.keycloakService.logout();
  }

  checkHasAnyRole(roles: string[]) {
    return roles.some((role) => this.keycloakService.getUserRoles().includes(role));
  }

  checkHasAllRoles(roles: string[]) {
    return roles.every((role) => this.keycloakService.getUserRoles().includes(role));
  }
}
