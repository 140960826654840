import {Logger} from 'viz-common';

export const loggerConfig = {
  isLoggerEnabled: true,
};

export function getLogger(location?: string) {
  const logger = new Logger(location);
  logger.isOn = loggerConfig.isLoggerEnabled;
  return logger;
}
